
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class Tile extends Vue {
  @Prop({ default: false }) private readonly isLoading!: boolean;

  spinnerTop: number = 0;

  mounted() {
    const element = this.$refs.tile as HTMLElement;
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      const tileHeight = element.getBoundingClientRect().height;
      const tileTop = element.getBoundingClientRect().top;
      this.spinnerTop = Math.min(tileHeight, window.innerHeight - tileTop) / 2;
    });

    resizeObserver.observe(element);

    this.$once("hook:beforeDestroy", () => {
      resizeObserver.disconnect();
    });
  }
}
