
import Button from "primevue/button";
import ScrollPanel from "primevue/scrollpanel";
import { ComponentPublicInstance } from "vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SyringeIcon from "@/components/icons/SyringeIcon.vue";
import config from "@/config";
import { Role } from "@/global";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

@Component({
  components: {
    SyringeIcon,
    Button,
    ScrollPanel,
  },
})
export default class MenuLinks extends Vue {
  @Ref("menuLinksWrapper") private menuLinksWrapper!: HTMLElement;
  @Ref("scrollPanel") private scrollPanel!: ComponentPublicInstance;

  @Prop() private openVaccinationModule!: () => void;

  private activeGroup: number = 0;
  private scrollHeight: number | null = null;

  readonly accountState: AccountModule = getModule(AccountModule);
  readonly sidebarState: SidebarModule = getModule(SidebarModule);
  readonly viewState: ViewModule = getModule(ViewModule);

  mounted() {
    if (this.menuLinksWrapper) {
      this.scrollHeight = this.menuLinksWrapper.scrollHeight;
    }
  }

  toggleGroup(index: number) {
    if (this.activeGroup === index) {
      this.activeGroup = -1;
    } else {
      this.activeGroup = index;
    }
    this.$nextTick(() => {
      if (this.scrollPanel?.$el?.children[0]?.children[0]) {
        this.scrollPanel.$el.children[0].children[0].scrollTop = +this.scrollHeight! * 3;
      }
    });
  }

  get helpLink(): string {
    return this.$t(`community.url.help${config.role}`).toString();
  }

  get loggedInUserRoles(): string[] {
    return this.accountState.loggedInUserRoles;
  }

  get isPatientSelected(): boolean {
    return !!this.accountState.selectedPatient;
  }

  get isProfessionalUser(): boolean {
    return (
      this.accountState.role == Role.HealthcareProfessional ||
      this.accountState.role == Role.Assistant
    );
  }

  // Currently always hidden until there's a second menu item before selecting a patient
  get showPatientSearch(): boolean {
    return this.isProfessionalUser && !this.isPatientSelected && false;
  }

  get showDocuments(): boolean {
    return (
      (this.isPatientSelected ||
        this.accountState.role == Role.Patient ||
        this.accountState.role == Role.Representative) &&
      !this.viewState.isSmallScreen
    );
  }

  get showSettings(): boolean {
    return (
      (this.accountState.role == Role.Patient || this.accountState.role == Role.Representative) &&
      this.loggedInUserRoles.includes(Role.Patient)
    );
  }

  get showDocumentAccess(): boolean {
    return (
      (this.accountState.role == Role.Patient || this.accountState.role == Role.Representative) &&
      this.loggedInUserRoles.includes(Role.Patient) &&
      !this.viewState.isSmallScreen
    );
  }

  get showAccessLogs(): boolean {
    return (
      (this.accountState.role == Role.Patient || this.accountState.role == Role.Representative) &&
      this.loggedInUserRoles.includes(Role.Patient) &&
      !this.viewState.isSmallScreen
    );
  }

  get showVaccination(): boolean {
    return (
      config.feature.vaccination &&
      (this.accountState.role == Role.Patient ||
        this.accountState.role == Role.Representative ||
        this.isPatientSelected) &&
      !this.viewState.isSmallScreen
    );
  }

  handleClick(): void {
    if (window.innerWidth < 768) {
      this.sidebarState.setIsSidebarOpen(false);
    }
  }
}
