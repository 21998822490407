
import ConfirmDialog from "primevue/confirmdialog";
import { NotificationOptions } from "vue-notification";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import config from "@/config";
import { Role } from "@/global";
import AccountModule from "@/store/modules/account";
import AuditEventModule from "@/store/modules/auditEvents";
import ConsentModule from "@/store/modules/consent";
import DocumentModule from "@/store/modules/document";
import HCProviderModule from "@/store/modules/hcprovider";
import NotificationsModule from "@/store/modules/notifications";

import Footer from "./components/Footer.vue";
import Navbar from "./components/Navbar.vue";
import MenuSidebar from "./components/navigation/MenuSidebar.vue";
import PatientTile from "./components/navigation/PatientTile.vue";
import TabBarMenu from "./components/navigation/TabBarMenu.vue";
import SidebarModule from "./store/modules/sidebar";
import ViewModule from "./store/modules/view";

// MTCaptcha Integration and config setting
(window as any).mtcaptchaConfig = config.mtcaptchaConfig;
(function () {
  var mt_service = document.createElement("script");
  mt_service.async = true;
  mt_service.src = "https://service.mtcaptcha.com/mtcv1/client/mtcaptcha.min.js";
  (
    document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
  ).appendChild(mt_service);
  var mt_service2 = document.createElement("script");
  mt_service2.async = true;
  mt_service2.src = "https://service2.mtcaptcha.com/mtcv1/client/mtcaptcha2.min.js";
  (
    document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
  ).appendChild(mt_service2);
})();
// *End* MTCaptcha Integration and config setting

enum NotificationType {
  SUCCESS = "success",
  WARN = "warn",
  ERROR = "error",
}

@Component({
  components: { Navbar, Footer, MenuSidebar, PatientTile, ConfirmDialog, TabBarMenu },
})
export default class App extends Vue {
  private accountState = getModule(AccountModule);
  private sidebarState = getModule(SidebarModule);
  private viewState = getModule(ViewModule);

  created() {
    this.viewState.initListeners();
    this.sidebarState.setIsSidebarOpen(window.innerWidth >= 1024);

    const shortLocale = this.$i18n.locale.split("-")[0];
    (window as any).mtcaptchaConfig.lang = shortLocale;
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", shortLocale);

    window.addEventListener("phellow:event:logout", () => {
      const accountState = getModule(AccountModule);
      const auditEventState = getModule(AuditEventModule);
      const consentState = getModule(ConsentModule);
      const documentState = getModule(DocumentModule);
      const hcproviderState = getModule(HCProviderModule);
      const notificationState = getModule(NotificationsModule);

      const idToken = accountState.idToken;

      accountState.logout();
      auditEventState.clearAuditEvents();
      consentState.clearConsent();
      documentState.clearDocuments();
      hcproviderState.clearCurrentHCP();
      notificationState.clearNotifications();

      if (idToken) {
        let logoutUrl = config.openid[accountState.selectedIdP]!!.logoutEndpoint;
        logoutUrl += "?id_token_hint=" + idToken;
        logoutUrl +=
          "&post_logout_redirect_uri=" + config.openid[accountState.selectedIdP]!!.redirectUri;
        window.location.href = logoutUrl;
      } else {
        if (this.$router.currentRoute.name !== "login") {
          this.$router.push({ name: "login" });
        }
      }
    });
  }

  logout() {
    window.dispatchEvent(new CustomEvent("phellow:event:logout"));
  }

  async openVaccinationModule() {
    let body = {
      firstName:
        this.accountState.parsedIDToken?.given_name || this.accountState.parsedIDToken?.sub,
      lastName:
        this.accountState.parsedIDToken?.family_name || this.accountState.parsedIDToken?.sub,

      language: this.$i18n.locale.replace("-", "_"),
      role: this.accountState.role,
      purpose: this.accountState.hasEmergencyAccess ? "EMER" : "NORM",
    };

    if (
      this.accountState.role == Role.HealthcareProfessional ||
      this.accountState.role == Role.Assistant
    ) {
      body = Object.assign(body, {
        gln: this.accountState.loggedInUserGLN,
      });
    }

    if (this.accountState.role == Role.Assistant) {
      body = Object.assign(body, {
        principalId: this.accountState.practitionerGLN,
        principalName: this.accountState.proxyPerson?.name,
      });
    }

    if (this.accountState.patientLocalID) {
      const [authority, value] = this.accountState.patientLocalID.split("|");
      body = Object.assign(body, {
        patientId: {
          value,
          authority,
        },
      });
    }

    try {
      const response = await fetch(config.eprServices.vaccinationRequest, {
        method: "POST",
        headers: {
          authorization: await this.accountState._getAuthenticationHeader(true),
          "content-type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const url = await response.text();
      window.open(url, "_blank");
    } catch (e) {
      Vue.notify({
        text: this.$t("toasts.vaccination.unableToLoad").toString(),
        type: "error",
        duration: -1,
      });
    }
  }

  get role(): Role {
    return this.accountState.role;
  }

  get isLoginPage(): boolean {
    return this.$route.path.startsWith("/login");
  }

  get showTabBar() {
    return (
      !this.isLoginPage &&
      this.viewState.isSmallScreen &&
      this.isTopLevelPage &&
      (this.accountState.role == Role.Patient || this.accountState.role == Role.Representative) &&
      this.accountState.loggedInUserRoles.includes(Role.Patient)
    );
  }

  get isTopLevelPage() {
    let pathname = this.$route.path;
    if (pathname.startsWith("/")) {
      pathname = pathname.substring(1);
    }
    if (pathname.endsWith("/")) {
      pathname = pathname.substring(0, pathname.length - 1);
    }
    return pathname.split("/").length === 1;
  }

  getNotificationClass(notification: NotificationOptions & { data: { srOnly?: boolean } }): object {
    switch (notification.type) {
      case NotificationType.SUCCESS:
        return { success: true, "sr-only": notification.data?.srOnly };
      case NotificationType.WARN:
        return { warn: true };
      case NotificationType.ERROR:
        return { error: true };

      default:
        return {};
    }
  }

  getNotificationRole(notification: NotificationOptions): string {
    if (notification.type === NotificationType.ERROR) {
      return "alert";
    } else {
      return "status";
    }
  }

  destroyed() {
    this.viewState.removeListeners();
  }
}
