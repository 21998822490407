
import Button from "primevue/button";
import Menubar from "primevue/menubar";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import SyringeIcon from "@/components/icons/SyringeIcon.vue";
import config from "@/config";
import { Role } from "@/global";
import AccountModule from "@/store/modules/account";

@Component({
  components: {
    Menubar,
    Button,
    SyringeIcon,
  },
})
export default class TabBarMenu extends Vue {
  @Prop() private openVaccinationModule!: () => void;
  private accountState = getModule(AccountModule);

  get showVaccination(): boolean {
    return (
      config.feature.vaccination &&
      (this.accountState.role == Role.Patient ||
        this.accountState.role == Role.Representative ||
        !!this.accountState.selectedPatient)
    );
  }
}
