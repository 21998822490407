
import Button from "primevue/button";
import Sidebar from "primevue/sidebar";
import { TranslateResult } from "vue-i18n";
import { Component, Prop, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";

import AppIcon from "@/components/AppIcon.vue";
import LanguageSelect from "@/components/LanguageSelect.vue";
import { Role } from "@/global";
import AccountModule from "@/store/modules/account";
import SidebarModule from "@/store/modules/sidebar";
import ViewModule from "@/store/modules/view";

import ActiveUser from "./ActiveUser.vue";
import MenuLinks from "./MenuLinks.vue";

@Component({
  components: {
    Sidebar,
    ActiveUser,
    AppIcon,
    Button,
    LanguageSelect,
    MenuLinks,
  },
})
export default class MenuSidebar extends Vue {
  @Prop({ default: Role.Patient }) private readonly role!: Role;
  @Prop() private readonly openVaccinationModule!: () => void;

  private sidebarState = getModule(SidebarModule);
  private accountState = getModule(AccountModule);
  private viewState = getModule(ViewModule);

  created() {
    if (this.accountState.parsedIDToken) {
      this.fetchProxies();
    }
  }

  setSidebarOpen(open: boolean) {
    this.sidebarState.setIsSidebarOpen(open);
  }

  async fetchProxies() {
    const failureMessage = await this.accountState.fetchProxies({ autoRepresent: true });

    if (failureMessage) {
      this.$notify({
        text: this.$t(failureMessage).toString(),
        type: "error",
        duration: -1,
      });
    }
  }

  get toggleIcon(): string {
    if (this.sidebarState.isSidebarOpen && this.viewState.isSmallScreen) {
      return "pi pi-times";
    } else if (this.sidebarState.isSidebarOpen) {
      return "pi pi-angle-double-left";
    } else {
      return "pi pi-bars";
    }
  }

  get title(): TranslateResult {
    /* eslint-disable no-fallthrough */
    switch (this.role) {
      case Role.Representative:
      case Role.Patient:
        return this.$t("navbar.titlePatient");
      case Role.Assistant:
      case Role.HealthcareProfessional:
        return this.$t("navbar.titleHealthcareprofessional");
      default:
        return "";
    }
    /* eslint-enable no-fallthrough */
  }

  get sidebarVisible(): boolean {
    // NOTE: temp fix for the sidebar not being visible on the register page
    if (
      !this.accountState.parsedIDToken ||
      this.$route.path.startsWith("/register") ||
      this.$route.path.startsWith("/login")
    ) {
      return false;
    }

    if (this.viewState.isSmallScreen) {
      return this.sidebarState.isSidebarOpen;
    }

    return true;
  }

  set sidebarVisible(value: boolean) {
    this.sidebarState.setIsSidebarOpen(value);
  }
}
